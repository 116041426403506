// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-best-odds-js": () => import("./../../../src/pages/best-odds.js" /* webpackChunkName: "component---src-pages-best-odds-js" */),
  "component---src-pages-calculators-js": () => import("./../../../src/pages/calculators.js" /* webpackChunkName: "component---src-pages-calculators-js" */),
  "component---src-pages-expected-value-calculator-js": () => import("./../../../src/pages/expected-value-calculator.js" /* webpackChunkName: "component---src-pages-expected-value-calculator-js" */),
  "component---src-pages-lottery-annuity-calculator-js": () => import("./../../../src/pages/lottery-annuity-calculator.js" /* webpackChunkName: "component---src-pages-lottery-annuity-calculator-js" */),
  "component---src-pages-lottery-number-generator-js": () => import("./../../../src/pages/lottery-number-generator.js" /* webpackChunkName: "component---src-pages-lottery-number-generator-js" */),
  "component---src-pages-lottery-odds-calculator-js": () => import("./../../../src/pages/lottery-odds-calculator.js" /* webpackChunkName: "component---src-pages-lottery-odds-calculator-js" */),
  "component---src-pages-lottery-tax-calculator-js": () => import("./../../../src/pages/lottery-tax-calculator.js" /* webpackChunkName: "component---src-pages-lottery-tax-calculator-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-recommendations-js": () => import("./../../../src/pages/recommendations.js" /* webpackChunkName: "component---src-pages-recommendations-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

