// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

import "prismjs/themes/prism.css"
import wrapWithProvider from "./wrap-with-provider"
export const wrapRootElement = wrapWithProvider

const addScript = url => {
  const script = document.createElement("script")
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

export const onClientEntry = () => {
  window.onload = () => {
    addScript("https://cse.google.com/cse.js?cx=014727289694448385917:dosw_tmofq8")
  }
}
