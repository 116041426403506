import { compose, createStore as reduxCreateStore } from "redux"

const reducer = (state, action) => {
  if (action.type === `UPDATE_GAME_OPTIONS`) {
    return {
      ...state,
      gameOptions: {
        ...state.gameOptions,
        ...action.gameOptions,
      },
    }
  }
  return state
}

const initialState = {
  gameOptions: {
    game: "Powerball (Multi-State)",
    count: 5,
    max: 69,
    extraCount: 1,
    extraMax: 26,
    unique: true,
  },
}

const composeEnhancers =
  typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const createStore = () => reduxCreateStore(
  reducer,
  initialState,
  composeEnhancers()
)
export default createStore
